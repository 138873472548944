<template>
  <div>
    <div class="flex items-start">
      <span
        :id="id"
        role="checkbox"
        tabindex="0"
        class="relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-hidden focus:ring-3"
        :class="{ 'bg-gray-500': !selected, 'bg-blue-600': selected }"
        :aria-checked="selected.toString()"
        :aria-label="label"
        @click="selected = !selected"
        @keydown.space.prevent="selected = !selected"
      >
        <span
          aria-hidden="true"
          class="relative inline-block size-5 rounded-full bg-white shadow-sm transition duration-200 ease-in-out dark:bg-gray-300"
          :class="{ 'translate-x-5': selected, 'translate-x-0': !selected }"
        >
          <span
            class="absolute inset-0 flex size-full items-center justify-center transition-opacity"
            :class="{
              'opacity-0 duration-100 ease-out': selected,
              'opacity-100 duration-200 ease-in': !selected,
            }"
          >
            <svg class="size-3 text-gray-500" fill="none" viewBox="0 0 12 12">
              <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span
            class="absolute inset-0 flex size-full items-center justify-center transition-opacity"
            :class="{
              'opacity-100 duration-200 ease-in': selected,
              'opacity-0 duration-100 ease-out': !selected,
            }"
          >
            <svg
              class="size-3 text-blue-600"
              fill="currentColor"
              viewBox="0 0 12 12"
            >
              <path
                d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
              />
            </svg>
          </span>
        </span>
      </span>

      <label
        v-if="label"
        class="form-label ml-3 text-gray-800 dark:text-gray-500"
        :for="id"
        @click="selected = !selected"
      >
        {{ label }}
        <template v-if="description">
          <span class="mt-1 block text-sm font-normal">{{ description }}</span>
        </template>
      </label>
    </div>

    <div v-if="errors.length" class="form-error">
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default() {
        return `toggle-input-${this._uid}`;
      },
    },
    value: {
      type: Boolean,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: this.value,
    };
  },
  watch: {
    selected(selected) {
      this.$emit('input', selected);
    },
  },
};
</script>

<template>
  <div :class="first ? null : 'mt-10 sm:mt-0'">
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <div class="px-4 sm:px-0">
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            {{ name }}
          </h3>
          <p class="mt-1 text-base leading-5 text-gray-500">
            {{ description }}
          </p>
        </div>
      </div>
      <div class="mt-5 md:col-span-2 md:mt-0">
        <div
          class="bg-white px-4 py-5 shadow-sm sm:rounded-md sm:p-6 dark:bg-gray-700"
        >
          <slot />
        </div>
      </div>
    </div>

    <div v-if="!last" class="hidden sm:block">
      <div class="py-5">
        <div class="border-t border-gray-200 dark:border-gray-700" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    first: {
      type: Boolean,
      required: false,
      default: false,
    },
    last: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

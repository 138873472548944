<template>
  <div>
    <portal-target name="modal" slim />

    <portal-target name="dropdown" slim />

    <div class="h-screen">
      <div
        class="fixed z-40 w-full bg-white transition duration-500 ease-out dark:bg-gray-700"
        :class="showNavbar ? 'translate-y-0 shadow-sm' : '-translate-y-full'"
        v-bind="$attrs"
      >
        <main-nav v-if="navBar" />

        <portal-target name="subnav" class="container mx-auto" slim />

        <flash-messages />
      </div>

      <main
        ref="main"
        class="flex h-full flex-col overflow-y-auto"
        :class="{
          'px-5 pb-8 pt-20 md:px-10': navBar && !fullScreen,
          'p-5': !navBar,
          'container mx-auto': !fullScreen,
        }"
        scroll-region
      >
        <slot />
      </main>
    </div>

    <portal-target name="footer" slim />
  </div>
</template>

<script>
import throttle from 'lodash/throttle';

export default {
  inheritAttrs: false,

  props: {
    navBar: {
      type: Boolean,
      default: true,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showNavbar: true,
      lastScrollPosition: 0,
    };
  },

  mounted() {
    this.$refs.main.addEventListener('scroll', throttle(this.onScroll, 100));
  },

  beforeDestroy() {
    this.$refs.main.removeEventListener('scroll', this.onScroll);
  },

  methods: {
    url() {
      return location.pathname.substr(1);
    },

    onScroll() {
      if (!this.$refs.main) return;

      const currentScrollPosition = this.$refs.main.scrollTop;

      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 0) return;

      // Don't continue for very small scrolling
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 15)
        return;

      // Show NavBar on scrolling UP only
      this.showNavbar = currentScrollPosition < this.lastScrollPosition;

      // Remember current scroll position
      this.lastScrollPosition = currentScrollPosition;
    },
  },
};
</script>

<i18n>
{
  "en": {
    "upload": "Upload files",
    "drag-drop": "or drag and drop"
  },
  "de": {
    "upload": "Lade Dateien hoch",
    "drag-drop": "oder verwende Drag & Drop"
  }
}
</i18n>

<template>
  <div
    class="relative rounded-sm border-2 border-dashed border-gray-300 p-5 dark:border-gray-500"
  >
    <draggable
      v-model="form.clips_attributes"
      class="flex flex-wrap"
      @end="updatePositions"
    >
      <div
        v-for="(attributes, index) in form.clips_attributes"
        :id="`clip-${attributes.id || attributes.tempId}`"
        :key="attributes.id || attributes.tempId"
        class="z-10 m-2 overflow-hidden rounded-sm"
      >
        <lazy-image
          v-if="attributes.id && currentClips[index].imageUrl"
          :src="currentClips[index].imageUrl"
          :blurhash="currentClips[index].blurhash"
          :width="128"
          :height="128"
          alt=""
          class="size-32 bg-gray-500"
        />

        <div v-else>
          <lazy-image
            v-if="currentClips[index] && currentClips[index].imageUrl"
            :src="currentClips[index].imageUrl"
            :blurhash="currentClips[index].blurhash"
            :width="128"
            :height="128"
            alt=""
            class="size-32 bg-gray-300 object-cover dark:bg-gray-600"
          />

          <div v-else class="size-32 bg-gray-300 dark:bg-gray-600" />

          <input v-model="attributes.file" type="hidden">
        </div>

        <div
          class="flex justify-between overflow-hidden rounded-b bg-gray-200 p-2 dark:bg-gray-600"
        >
          <input v-model="attributes.id" type="hidden">
          <input v-model="attributes._destroy" type="hidden">

          <div
            v-if="
              currentClips[index] &&
                currentClips[index].progress >= 0 &&
                currentClips[index].progress < 100
            "
            class="w-full"
          >
            <div
              class="h-4 rounded-sm bg-gray-500"
              :style="`width: ${currentClips[index].progress}%`"
            />
          </div>

          <button
            v-else-if="attributes._destroy"
            type="button"
            title="Will be deleted, click to undo"
            @click="undoRemove(index)"
          >
            <icon class="w-4 fill-red-600" name="trash" />
          </button>

          <button
            v-else
            type="button"
            :title="
              attributes.id ? 'Click to mark for deletion' : 'Click to remove'
            "
            @click="removeClip(index)"
          >
            <icon
              class="w-4"
              :class="
                attributes.id
                  ? 'fill-gray-500 dark:fill-gray-900'
                  : 'fill-gray-900'
              "
              name="trash"
            />
          </button>

          <div
            v-if="currentClips[index] && currentClips[index].error"
            class="inline"
          >
            <span
              class="cursor-default text-xl font-black text-red-600"
              :title="currentClips[index].error"
            >! {{ currentClips[index].error }}</span>
          </div>
        </div>
      </div>
    </draggable>

    <div class="z-0 w-full cursor-pointer text-center">
      <icon name="upload" class="mx-auto mt-5 size-12 text-gray-400" />

      <p class="mt-1 text-sm text-gray-600 dark:text-gray-400">
        <span class="font-medium text-blue-600 dark:text-blue-300">
          {{ $t('upload') }}
        </span>
        {{ $t('drag-drop') }}
      </p>

      <p class="mt-1 text-xs text-gray-500">
        JPG, PNG, GIF, MP4, MP3
      </p>

      <input
        type="file"
        multiple
        accept="image/jpeg,image/png,video/mp4,video/quicktime,audio/mpeg"
        class="absolute left-0 top-0 size-full cursor-pointer opacity-0"
        @change="uploadFiles"
      >
    </div>
  </div>
</template>

<script>
import loadImage from 'blueimp-load-image';
import { DirectUpload } from '@rails/activestorage';
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
  },

  props: {
    clips: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      currentClips: this.clips,
    };
  },

  computed: {
    form: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },

  methods: {
    updatePositions() {
      this.form.clips_attributes.forEach((clip_attributes, index) => {
        this.form.clips_attributes[index].position = index + 1;
      });
    },

    addClip(attributes) {
      this.currentClips.push({
        imageUrl: attributes.imageUrl,
        filename: attributes.filename,
        progress: attributes.progress,
      });
      this.form.clips_attributes.push({
        tempId: -this.form.clips_attributes.length, // needs to be uniq
        file: null,
        _destroy: 0,
        position: this.form.clips_attributes.length + 1,
      });
    },

    removeClip(index) {
      if (this.form.clips_attributes[index].id) {
        this.form.clips_attributes[index]._destroy = 1;
      } else {
        this.currentClips.splice(index, 1);
        this.form.clips_attributes.splice(index, 1);
      }
    },

    undoRemove(index) {
      this.form.clips_attributes[index]._destroy = 0;
    },

    uploadFiles(event) {
      const files = event.target.files || event.dataTransfer.files;
      Array.from(files).forEach((file) => this.processFile(file));
    },

    ///////////////// private /////////////////

    processFile(file) {
      if (
        !(
          file.type == 'image/jpeg' ||
          file.type == 'image/png' ||
          file.type == 'video/mp4' ||
          file.type == 'video/quicktime' ||
          file.type == 'audio/mpeg'
        )
      )
        return;

      // Step 1: Add empty Clip
      this.addClip({
        filename: file.name,
        progress: 0,
        imageUrl: null,
      });

      // Step 2: Generate thumbnail
      if (file.type.startsWith('image/')) this.imageThumbnail(file);
      else if (file.type.startsWith('video/')) this.videoThumbnail(file);

      // Step 3: Upload the file
      new Uploader(file, this.$routes.direct_uploads(), this);
    },

    coordinates(exif) {
      var gpsInfo = exif && exif.get('GPSInfo');

      if (gpsInfo) {
        var exifLat = gpsInfo.get('GPSLatitude');
        var exifLatRef = gpsInfo.get('GPSLatitudeRef');
        var latitude;
        if (exifLatRef == 'S') {
          latitude =
            exifLat[0] * -1 + (exifLat[1] * -60 + exifLat[2] * -1) / 3600;
        } else {
          latitude = exifLat[0] + (exifLat[1] * 60 + exifLat[2]) / 3600;
        }

        var exifLong = gpsInfo.get('GPSLongitude');
        var exifLongRef = gpsInfo.get('GPSLongitudeRef');
        var longitude;
        if (exifLongRef == 'W') {
          longitude =
            exifLong[0] * -1 + (exifLong[1] * -60 + exifLong[2] * -1) / 3600;
        } else {
          longitude = exifLong[0] + (exifLong[1] * 60 + exifLong[2]) / 3600;
        }

        return {
          latitude,
          longitude,
        };
      }
    },

    imageThumbnail(file) {
      loadImage(
        file,
        (image, data) => {
          if (image.type === 'error') {
            this.updateClip(file, { error: 'Thumbnailing failed!' });
          } else {
            const coordinates = this.coordinates(data.exif);
            if (coordinates) this.$emit('coordinates', coordinates);

            this.updateClip(file, { imageUrl: image.toDataURL() });
          }
        },
        {
          maxWidth: 500,
          maxHeight: 500,
          meta: true,
          crop: true,
          contain: true,
          orientation: true,
        },
      );
    },

    videoThumbnail(file) {
      var video = document.createElement('video');
      var canvas = document.createElement('canvas');

      video.addEventListener(
        'loadedmetadata',
        () => {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
        },
        { once: true },
      );

      video.addEventListener(
        'timeupdate',
        () => {
          canvas
            .getContext('2d')
            .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

          this.updateClip(file, { imageUrl: canvas.toDataURL() });
        },
        { once: true },
      );

      video.setAttribute('src', URL.createObjectURL(file));
      video.currentTime = 2; // 2 seconds after beginning
    },

    updateClip(file, attributes) {
      const index = this.currentClips.findIndex((clip) => {
        return clip.filename === file.name;
      });

      if (index >= 0) {
        if (attributes.blobId)
          this.form.clips_attributes[index].file = attributes.blobId;
        else Object.assign(this.currentClips[index], attributes);
      }
    },
  },
};

import Vue from 'vue';
class Uploader {
  constructor(file, url, delegate) {
    this.delegate = delegate;
    this.file = file;

    const upload = new DirectUpload(file, url, this);

    upload.create((error, blob) => {
      if (error) {
        // Handle the error
        delegate.updateClip(file, { error: error });
        if (Vue.$honeybadger) Vue.$honeybadger.notify(error);
        else alert(error);
      } else {
        // Add an appropriately-named hidden input to the form
        // with a value of blob.signed_id
        delegate.updateClip(file, { blobId: blob.signed_id });
      }
    });
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', (event) =>
      this.directUploadDidProgress(event),
    );
  }

  directUploadDidProgress(event) {
    this.delegate.updateClip(this.file, {
      progress: (100 * event.loaded) / event.total,
    });
  }
}
</script>

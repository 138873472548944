<i18n>
{
  "en": {
    "of": "of",
    "download": "Download"
  },
  "de": {
    "of": "von",
    "download": "Herunterladen"
  }
}
</i18n>

<template>
  <div>
    <modal ref="modal" :title="post.title">
      <template v-if="currentClip" #head>
        <div class="flex items-center text-sm">
          <span>
            {{ currentClipIndex + 1 }} {{ $t('of') }} {{ post.clips.length }}
          </span>

          <a
            download
            :href="currentClip.fullUrl"
            :aria-label="$t('download')"
            class="ml-4 rounded-md border border-gray-300 bg-white px-2 py-1 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-hidden focus:ring-3 active:bg-gray-50 active:text-gray-800"
          >
            <icon name="download" class="size-5" />
          </a>
        </div>
      </template>

      <div v-if="prevClip" class="fixed inset-y-1/2 left-0 z-10">
        <button
          type="button"
          class="rounded-r bg-gray-200 px-5 py-1 text-3xl leading-normal text-gray-800 opacity-75"
          @click="openPrevClip"
        >
          ‹
        </button>
      </div>

      <div class="h-full w-screen">
        <div v-if="currentClip">
          <img
            v-show="!clipLoaded"
            :src="currentClip.thumbUrl"
            :width="currentClip.width"
            :height="currentClip.height"
            class="maximized mx-auto h-full object-contain"
          >
          <canvas
            v-show="clipLoaded"
            ref="imageCanvas"
            :width="currentClip.width"
            :height="currentClip.height"
            class="mx-auto max-w-full"
            :class="{
              'max-h-full': clipZoomed,
              maximized: !clipZoomed,
              'cursor-zoom-in': clipZoomable,
              'cursor-zoom-out': clipZoomable && clipZoomed,
            }"
            @click="zoomClip()"
          />
        </div>
      </div>

      <div v-if="nextClip" class="fixed inset-y-1/2 right-0 z-10">
        <button
          type="button"
          class="rounded-l bg-gray-200 px-5 py-1 text-3xl leading-normal text-gray-800 opacity-75"
          @click="openNextClip"
        >
          ›
        </button>
      </div>
    </modal>

    <div class="-mx-5 mt-5 sm:mx-0">
      <masonry :cols="masonryCols" :gutter="20">
        <div
          v-for="(clip, index) in imageClips"
          :key="index"
          class="mb-4 w-full cursor-pointer overflow-hidden bg-gray-500 sm:rounded-sm"
          @click="openClip(clip)"
        >
          <lazy-image
            :src="imgUrl(clip)"
            :blurhash="clip.blurhash"
            :width="clip.width"
            :height="clip.height"
            alt=""
          />
        </div>
      </masonry>
    </div>
  </div>
</template>

<script>
import loadImage from 'blueimp-load-image';
import debounce from 'lodash/debounce';

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      currentClip: null,
      clipZoomed: false,
      clipZoomable: false,
      clipLoaded: false,
    };
  },

  computed: {
    currentClipIndex() {
      if (this.currentClip)
        return this.post.clips.findIndex((clip) => clip == this.currentClip);
      else return 0;
    },

    imageClips() {
      return this.post.clips.filter((clip) =>
        clip.content_type.startsWith('image/'),
      );
    },

    isOneImageOnly() {
      return this.imageClips.length == 1;
    },

    masonryCols() {
      if (this.isOneImageOnly)
        // If there is one image only, show it with full width
        return 1;
      // Otherwhise make columns dependent on screen size
      else return { default: 3, 1920: 2, 768: 1 };
    },

    nextClip() {
      if (this.currentClipIndex + 1 < this.post.clips.length)
        return this.post.clips[this.currentClipIndex + 1];
      else return null;
    },

    prevClip() {
      if (this.currentClipIndex > 0)
        return this.post.clips[this.currentClipIndex - 1];
      else return null;
    },
  },

  created() {
    this.debouncedLoadImage = debounce(loadImage, 300);
    window.addEventListener('keydown', this.keyDownHandler);
  },

  destroyed() {
    window.removeEventListener('keydown', this.keyDownHandler);
  },

  methods: {
    keyDownHandler(event) {
      if (this.$refs.modal.showModal) {
        switch (event.key) {
          case 'Left':
          case 'ArrowLeft':
            this.openPrevClip();
            break;
          case 'Right':
          case 'ArrowRight':
            this.openNextClip();
            break;
        }
      }
    },

    imgUrl(clip) {
      return this.isOneImageOnly ? clip.fullUrl : clip.thumbUrl;
    },

    openClip(clip) {
      if (!clip) return;

      this.$refs.modal.show();
      this.clipZoomed = false;
      this.currentClip = clip;
      this.clipLoaded = false;
      this.debouncedLoadImage.cancel();
      this.debouncedLoadImage(
        clip.fullUrl,
        (img, data) => {
          if (!this.$refs.imageCanvas) return;

          var canvas = this.$refs.imageCanvas;
          var ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          this.clipLoaded = true;

          // Wait a bit before checking if image is zoomable,
          // otherweise canvas.scrollWidth is 0 (strange...)
          setTimeout(() => {
            this.clipZoomable =
              canvas.scrollWidth < data.originalWidth &&
              canvas.scrollWidth < document.body.clientWidth;
          }, 0);
        },
        {
          orientation: true,
          maxWidth: 5000,
        },
      );
    },

    openNextClip() {
      this.openClip(this.nextClip);
    },

    openPrevClip() {
      this.openClip(this.prevClip);
    },

    zoomClip() {
      this.clipZoomed = !this.clipZoomed;
    },
  },
};
</script>

<style lang="css">
.maximized {
  /* full screen height, but without modal header */
  max-height: calc(100vh - 57px);
}
</style>

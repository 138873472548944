<i18n>
{
  "en": {
    "hit": "view | views",
    "publish-post": "Publish",
    "edit-post": "Edit Post",
    "comments": "Comments",
    "nearby-posts": "Posts nearby",
    "similar-posts": "Similar Posts"
  },
  "de": {
    "hit": "Aufruf | Aufrufe",
    "publish-post": "Veröffentlichen",
    "edit-post": "Bearbeiten",
    "comments": "Kommentare",
    "nearby-posts": "Beiträge in der Nähe",
    "similar-posts": "Ähnliche Beiträge"
  }
}
</i18n>

<template>
  <div>
    <section>
      <page-title :title="post.title">
        <div
          v-if="!post.published"
          slot="title"
          class="ml-3 size-8 rounded-full bg-blue-200"
        />

        <div class="z-20 mt-4 flex shrink-0 justify-between md:ml-4 md:mt-0">
          <action-button
            v-if="can.publish_post"
            as="button"
            type="button"
            kind="secondary"
            icon="sparkles"
            class="mr-2"
            :aria-label="$t('publish-post')"
            :disabled="sending"
            @click="publish()"
          >
            {{ $t('publish-post') }}
          </action-button>

          <action-button
            v-if="can.update_post"
            kind="secondary"
            icon="pencil-alt"
            :href="$routes.edit_post(post.slug)"
            :aria-label="$t('edit-post')"
          >
            {{ $t('edit-post') }}
          </action-button>

          <post-voting
            v-if="post.published"
            class="ml-2"
            :post="post"
            :votes="votes"
          />
        </div>
      </page-title>

      <div>
        <div class="mt-2">
          <time :datetime="post.date">
            {{ $d(new Date(post.date), 'long') }}
          </time>
          &bull;
          <span>{{ post.hits }} {{ $tc('hit', post.hits) }}</span>
        </div>

        <div v-if="post.location">
          <inertia-link
            :href="$routes.post_map_index(post.slug)"
            class="mt-3 flex items-center text-sm leading-normal hover:underline"
          >
            <icon name="location-marker" class="mr-2 w-4" />
            {{ post.location }}
          </inertia-link>
        </div>

        <div v-if="post.tags" class="mt-5">
          <inertia-link
            v-for="tag in post.tags"
            :key="tag"
            class="mr-2 rounded-sm bg-gray-200 px-2 py-1 font-medium text-gray-500 dark:bg-gray-600 dark:text-gray-400"
            :href="$routes.search_posts(tag)"
          >
            <span class="mr-1">#</span>{{ tag }}
          </inertia-link>
        </div>

        <div
          v-if="post.content"
          class="prose mt-5 max-w-none text-xl leading-normal"
          v-html="post.content"
        />
      </div>
    </section>

    <section>
      <PostImages v-if="hasContentType('image/')" :post="post" />
      <PostVideos v-if="hasContentType('video/')" :post="post" />
      <PostAudios v-if="hasContentType('audio/')" :post="post" />
    </section>

    <section v-if="comments.length > 0 || can.create_comment" class="mt-16">
      <section-title id="comments">
        {{ $t('comments') }}
      </section-title>

      <PostComment
        v-for="comment in comments"
        :key="comment.id"
        :comment="comment"
        :post="post"
      />

      <PostCommentForm v-if="can.create_comment" :post="post" />
    </section>

    <section v-if="nearbyPosts.length > 0" class="mt-16">
      <section-title>
        {{ $t('nearby-posts') }}
      </section-title>

      <div class="grid grid-cols-2 gap-5 lg:grid-cols-4">
        <post-card
          v-for="nearbyPost in nearbyPosts"
          :key="nearbyPost.id"
          :post="nearbyPost"
        />
      </div>
    </section>

    <section v-if="similarPosts.length > 0" class="mt-16">
      <section-title>
        {{ $t('similar-posts') }}
      </section-title>

      <div class="grid grid-cols-2 gap-5 lg:grid-cols-4">
        <post-card
          v-for="similarPost in similarPosts"
          :key="similarPost.id"
          :post="similarPost"
        />
      </div>
    </section>
  </div>
</template>

<script>
import PostCard from './_Card.vue';
import PostAudios from './_Audios.vue';
import PostVideos from './_Videos.vue';
import PostImages from './_Images.vue';
import PostVoting from './_Voting.vue';
import PostComment from './_Comment.vue';
import PostCommentForm from './_CommentForm.vue';

export default {
  metaInfo() {
    return { title: this.post.title };
  },

  components: {
    PostCard,
    PostAudios,
    PostVideos,
    PostImages,
    PostVoting,
    PostComment,
    PostCommentForm,
  },

  props: {
    post: {
      type: Object,
      required: true,
    },
    can: {
      type: Object,
      required: true,
    },
    nearbyPosts: {
      type: Array,
      required: true,
    },
    similarPosts: {
      type: Array,
      required: true,
    },
    votes: {
      type: Object,
      required: true,
    },
    comments: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      sending: false,
    };
  },

  methods: {
    hasContentType(content_type) {
      if (this.post.clips.length < 1) return false;

      return this.post.clips.some((clip) => {
        return clip.content_type.startsWith(content_type);
      });
    },

    publish() {
      this.$inertia.put(this.$routes.publish_post(this.post.slug), {
        onStart: () => (this.sending = true),
        onFinish: () => (this.sending = false),
      });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "delete": "Delete",
    "delete-confirm": "Are you sure you want to delete this comment?"
  },
  "de": {
    "delete": "Löschen",
    "delete-confirm": "Diesen Kommentar wirklich löschen?"
  }
}
</i18n>

<template>
  <article class="mb-5 text-lg">
    <speech-bubble class="mt-8" v-html="contentWithBreaks" />

    <UserCard class="mt-4" :user="comment.user" :date="comment.created_at">
      <button
        v-if="comment.can.destroy_comment"
        class="underline focus:outline-hidden"
        @click="destroyComment"
      >
        {{ $t('delete') }}
      </button>
    </UserCard>
  </article>
</template>

<script>
import UserCard from '../Users/_Card.vue';

export default {
  components: { UserCard },

  props: {
    comment: {
      type: Object,
      required: true,
    },
    post: {
      type: Object,
      required: true,
    },
  },

  computed: {
    contentWithBreaks() {
      return this.comment.content.split('\n').join('<br>');
    },
  },

  methods: {
    destroyComment() {
      if (confirm(this.$t('delete-confirm'))) {
        this.$inertia.delete(
          this.$routes.post_comment(this.post, this.comment),
          {
            preserveState: true,
            preserveScroll: true,
            only: ['comments', 'flash', 'errors'],
          },
        );
      }
    },
  },
};
</script>

<i18n>
{
  "en": {
    "title": "Title",
    "date": "Date",
    "content": "Text",
    "location": "Location",
    "tags": "Tags",
    "search-words": "Search words",
    "main": "Description",
    "main-description": "Subject of this post",
    "clips": "Clips",
    "clips-description": "Images, videos and audio files",
    "meta": "Meta",
    "meta-description": "Categorize and search"
  },
  "de": {
    "title": "Titel",
    "date": "Datum",
    "content": "Text",
    "location": "Ort",
    "tags": "Schlagworte",
    "search-words": "Suchbegriffe",
    "main": "Beschreibung",
    "main-description": "Thema dieses Beitrags",
    "clips": "Medien",
    "clips-description": "Bilder, Videos und Audio-Dateien",
    "meta": "Meta",
    "meta-description": "Einordnen und Suchen"
  }
}
</i18n>

<template>
  <form id="post-form" class="relative" @submit.prevent="$emit('submit')">
    <input type="submit" class="invisible absolute">

    <FormGroup
      :first="true"
      :name="$t('clips')"
      :description="$t('clips-description')"
    >
      <file-upload
        v-model="form"
        :clips="clips"
        class="-m-1"
        @coordinates="onCoordinates"
      />
    </FormGroup>

    <FormGroup :name="$t('main')" :description="$t('main-description')">
      <text-input
        v-model="form.title"
        :errors="$page.props.errors && $page.props.errors.title"
        class="w-full py-3"
        :label="$t('title')"
        :autofocus="true"
      />

      <textarea-input
        v-model="form.content"
        :errors="$page.props.errors && $page.props.errors.content"
        class="w-full py-3"
        rows="3"
        :label="$t('content')"
      />
    </FormGroup>

    <FormGroup
      :name="$t('meta')"
      :description="$t('meta-description')"
      :last="true"
    >
      <div class="grid grid-cols-6 gap-6">
        <div class="col-span-6 sm:col-span-3">
          <text-input
            ref="mapField"
            v-model="form.location"
            type="search"
            :errors="$page.props.errors && $page.props.errors.location"
            class="w-full py-3"
            :label="$t('location')"
            @keydown.enter.native.prevent
          />
          <text-input
            v-model="form.date"
            type="date"
            :errors="$page.props.errors && $page.props.errors.date"
            class="w-full py-3 lg:w-1/2"
            :label="$t('date')"
          />
          <tag-select
            v-model="form.tags"
            :errors="$page.props.errors && $page.props.errors.tags"
            class="w-full py-3"
            :label="$t('tags')"
          />
          <textarea-input
            v-model="form.search_words"
            :errors="$page.props.errors && $page.props.errors.search_words"
            class="w-full pt-3"
            rows="2"
            :label="$t('search-words')"
          />
        </div>
        <div class="col-span-6 sm:col-span-3">
          <div ref="map" class="h-72 w-full rounded-sm md:h-full" />
        </div>
      </div>
    </FormGroup>
  </form>
</template>

<script>
import loadGoogleMapsApi from 'load-google-maps-api';

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    clips: {
      type: Array,
      required: true,
    },
  },

  computed: {
    form: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },

  mounted() {
    loadGoogleMapsApi({
      key: document.querySelector('meta[name="google-maps-key"]').content,
      language: document.documentElement.lang,
      libraries: ['places'],
    }).then((googleMaps) => {
      if (this.$refs.map) {
        this.googleMaps = googleMaps;
        this.map = new googleMaps.Map(this.$refs.map, {
          center: {
            lat: this.form.latitude || 50,
            lng: this.form.longitude || 9,
          },
          zoom: this.form.latitude == null ? 5 : 13,
          mapTypeId: 'terrain',
        });
        this.geocoder = new googleMaps.Geocoder();

        this.autocomplete = new googleMaps.places.Autocomplete(
          this.$refs.mapField.$refs.input,
        );
        this.autocomplete.bindTo('bounds', this.map);
        this.autocomplete.setFields([
          'address_components',
          'geometry',
          'icon',
          'name',
        ]);
        this.autocomplete.addListener(
          'place_changed',
          this.placeChanged.bind(this),
        );

        this.addMarker({
          lat: this.form.latitude || 0,
          lng: this.form.longitude || 0,
        });
      }
    });
  },

  beforeDestroy() {
    if (this.googleMaps) {
      this.googleMaps.event.clearInstanceListeners(this.autocomplete);
      this.googleMaps.event.clearInstanceListeners(this.marker);
      var elem = document.querySelector('.pac-container');
      if (elem) elem.parentNode.removeChild(elem);
    }
  },

  methods: {
    placeChanged() {
      let place = this.autocomplete.getPlace();

      if (!place.geometry) {
        window.alert(`No details available for input: ${place.name}`);
        return;
      }

      if (place.geometry.viewport) {
        this.map.fitBounds(place.geometry.viewport);
      } else {
        this.map.setCenter(place.geometry.location);
        this.map.setZoom(17);
      }

      this.marker.setPosition(place.geometry.location);
      this.marker.setVisible(true);

      this.form.latitude = place.geometry.location.lat();
      this.form.longitude = place.geometry.location.lng();

      this.form.suggested_bound_sw_lat = place.geometry.viewport
        .getSouthWest()
        .lat();
      this.form.suggested_bound_sw_lng = place.geometry.viewport
        .getSouthWest()
        .lng();
      this.form.suggested_bound_ne_lat = place.geometry.viewport
        .getNorthEast()
        .lat();
      this.form.suggested_bound_ne_lng = place.geometry.viewport
        .getNorthEast()
        .lng();

      this.form.location = this.$refs.mapField.$refs.input.value;
    },

    addMarker(latlng) {
      this.marker = new this.googleMaps.Marker({
        map: this.map,
        draggable: true,
        position: latlng,
        anchorPoint: new this.googleMaps.Point(0, -29),
      });
      this.googleMaps.event.addListener(
        this.marker,
        'dragend',
        this.placeDragged.bind(this),
      );
    },

    placeDragged(event) {
      this.form.latitude = event.latLng.lat();
      this.form.longitude = event.latLng.lng();
    },

    onCoordinates(coordinates) {
      if (!this.form.latitude && !this.form.longitude) {
        var latlng = {
          lat: coordinates.latitude,
          lng: coordinates.longitude,
        };

        this.geocoder.geocode({ location: latlng }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              this.map.setCenter(latlng);
              this.map.setZoom(13);
              this.marker.setPosition(latlng);

              this.form.latitude = coordinates.latitude;
              this.form.longitude = coordinates.longitude;
              this.form.location = results[0].formatted_address;

              this.$refs.mapField.$refs.input.value =
                results[0].formatted_address;
            }
          }
        });
      }
    },
  },
};
</script>

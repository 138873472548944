<i18n>
{
  "en": {
    "browse": "Browse",
    "remove": "Remove"
  },
  "de": {
    "browse": "Durchsuchen",
    "remove": "Entfernen"
  }
}
</i18n>

<template>
  <div>
    <label v-if="label" class="form-label">{{ label }}</label>
    <div :class="{ error: errors.length }">
      <input
        ref="file"
        type="file"
        :accept="accept"
        class="hidden"
        v-bind="$attrs"
        @change="change"
      >

      <button
        v-if="!value"
        type="button"
        class="rounded-md border border-gray-300 px-3 py-2 text-sm font-medium leading-4 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-hidden focus:ring-3 active:bg-gray-50 active:text-gray-800"
        @click="browse"
      >
        {{ $t('browse') }}
      </button>
      <div v-else class="flex items-center justify-between p-2">
        <div class="flex-1 pr-5">
          {{ value.name }}
          <span class="text-xs text-gray-600">({{ filesize(value.size) }})</span>
        </div>
        <button
          type="button"
          class="rounded-md border border-gray-300 px-3 py-2 text-sm font-medium leading-4 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-hidden focus:ring-3 active:bg-gray-50 active:text-gray-800"
          @click="remove"
        >
          {{ $t('remove') }}
        </button>
      </div>
    </div>
    <div v-if="errors.length" class="form-error">
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: File,
    label: {
      type: String,
      default: null,
    },
    accept: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    value(value) {
      if (!value) {
        this.$refs.file.value = '';
      }
    },
  },
  methods: {
    filesize(size) {
      let i = Math.floor(Math.log(size) / Math.log(1024));
      return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        ' ' +
        ['B', 'kB', 'MB', 'GB', 'TB'][i]
      );
    },
    browse() {
      this.$refs.file.click();
    },
    change(e) {
      this.$emit('input', e.target.files[0]);
    },
    remove() {
      this.$emit('input', null);
    },
  },
};
</script>

<i18n>
{
  "en": {
    "new": "New!",
    "draft": "draft"
  },
  "de": {
    "new": "Neu!",
    "draft": "Entwurf"
  }
}
</i18n>

<template>
  <inertia-link
    :href="$routes.post(post.slug)"
    :title="post.title"
    class="post-link relative rounded-sm bg-gray-500 shadow-lg"
    @click="$emit('click', post)"
  >
    <div>
      <div
        v-if="post.unread"
        class="absolute left-0 top-0 z-10 -my-3 -ml-3 size-8 rounded-full border-4 border-gray-50 bg-red-300"
        :title="$t('new')"
      />

      <div
        v-if="!post.published"
        class="absolute right-0 top-0 z-10 -my-3 -mr-3 size-8 rounded-full border-4 border-gray-50 bg-blue-300"
        :title="$t('draft')"
      />

      <lazy-image
        v-if="post.imageUrl"
        :src="post.imageUrl"
        :blurhash="post.blurhash"
        :width="500"
        :height="500"
        alt=""
        class="sm:overflow-hidden"
        :class="showMeta ? 'sm:rounded-t' : 'sm:rounded-sm'"
      />

      <div
        v-if="post.kind == 'video'"
        class="absolute left-0 top-0 size-full p-20 text-white"
      >
        <svg
          fill="none"
          stroke-opacity="0.3"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.3"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
          />
          <path d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      </div>

      <div v-if="showMeta" class="rounded-b bg-gray-50 p-4 dark:bg-gray-600">
        <div
          class="text-xs font-semibold tracking-wide text-gray-700 dark:text-gray-400"
        >
          <time :datetime="post.date">
            {{ $d(new Date(post.date), 'short') }}
          </time>
        </div>

        <h4
          class="mt-1 truncate text-base font-semibold leading-tight text-gray-900 lg:text-lg dark:text-gray-300"
        >
          {{ post.title }}
        </h4>
      </div>
    </div>
  </inertia-link>
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      required: true,
    },

    showMeta: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
